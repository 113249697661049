import React, {Component} from 'react';
import {
  withRouter
} from 'react-router-dom';
import moment from 'moment';

class EventCreateReview extends Component {

  constructor(props) {
    super(props);

    this.handleReviewApproveClick = this.handleReviewApproveClick.bind(this);
    this.handleReviewRejectClick = this.handleReviewRejectClick.bind(this);
  }

  handleReviewApproveClick(){
    this.props.requestSender.post(`/admin/events/${this.props.event._id}/review`, {
      approve: true
    }).then((res) => {
      this.props.handleEventStateChange("REVIEWED");
      this.props.history.push(`/event/create/${this.props.event._id}/publish`);
    }).catch((err) => {

    });
  }

  handleReviewRejectClick(){
    this.props.requestSender.post(`/admin/events/${this.props.event._id}/review`, {
      approve: false
    }).then((res) => {
      this.props.handleEventStateChange("IN_PROGRESS");
      this.props.history.push(`/event/create/${this.props.event._id}/information`);
    }).catch((err) => {

    })
  }

  render() {
    return (
        <div className="section">
          <div className="block">
            <h1 className="title">Event Prüfen</h1>
          </div>
          <div className="columns">
            <div className="column">
              <div className="block">
                <div className="field">
                  <label className="label">Name</label>
                  <div className="content">
                    <p>{this.props.event.name}</p>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Beschreibung</label>
                  <div className="content">
                    <p>{this.props.event.description}</p>
                  </div>
                </div>
              </div>

              <div className="block">
                <div className="field">
                  <label className="label">Von</label>
                  <div className="content">
                    <p>{moment.unix(this.props.event.date.from).format("DD.MM.YYYY HH:mm")}</p>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Bis</label>
                  <div className="content">
                    <p>{moment.unix(this.props.event.date.to).format("DD.MM.YYYY HH:mm")}</p>
                  </div>
                </div>
              </div>

              <div className="block">
                <label className="label">Adresse</label>
                { this.props.event.address.type === "PRESENCE" ?
                    <div>
                      <div className="field">
                        <div className="content">
                          <p>{this.props.event.address.name}</p>
                        </div>
                      </div>
                      <div className="field">
                        <div className="content">
                          <p>{this.props.event.address.street} {this.props.event.address.houseNumber}</p>
                        </div>
                      </div>
                      <div className="field">
                        <div className="content">
                          <p>{this.props.event.address.postcode} {this.props.event.address.city}</p>
                        </div>
                      </div>
                    </div>
                :
                    <div className="field">
                      <div className="content">
                        <p>{this.props.event.address.platform} {this.props.event.address.link}</p>
                      </div>
                    </div>
                }
              </div>

              <div className="block">
                <div className="field">
                  <label className="label">Anmeldung</label>
                  <div className="content">
                    <p>Benötigt: {this.props.event.registration.required ? "Ja" : "Nein"}</p>
                  </div>
                </div>
                {this.props.event.registration.required ?
                    <div>
                      <div className="field">
                        <div className="content">
                          <p>Bis: {moment.unix(this.props.event.registration.until).format("DD.MM.YYYY")}</p>
                        </div>
                      </div>
                      <div className="field">
                        <div className="content">
                          <p>Teilnehmer: {this.props.event.registration.hasLimit ? `Maximal ${this.props.event.registration.limit}` : "Unbegrenzt"}</p>
                        </div>
                      </div>
                    </div>
                :
                    ""
                }
              </div>

              <div className="block">
                {this.props.user.permission === "ADMIN" ?
                  <div className="buttons">
                    <button className="button is-warning" onClick={this.handleReviewRejectClick}>Ablehnen</button>
                    <button className="button is-success" onClick={this.handleReviewApproveClick}>Genehmigen</button>
                  </div>
                :
                  <div className="button" disabled>
                    <span className="icon is-small">
                      <div className="loader-wrapper is-active" style={{"padding": "0"}}>
                        <div className="loader is-loading" style={{"width": "1rem", "height": "1rem"}}>
                        </div>
                      </div>
                    </span>
                    <span>Event wird geprüft</span>
                  </div>
                }
              </div>
            </div>
            <div className="column is-one-third">
              <figure className="image is-1by1">
                <img className="is-rounded-4px" src={this.props.event.images.overview.src}></img>
              </figure>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(EventCreateReview);
