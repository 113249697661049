import React, {Component} from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';
import moment from 'moment';
class EventsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      events: []
    }
  }

  componentDidMount() {

    this.props.requestSender.get("/admin/events").then((res) => {
      this.setState({
        events: res.data.events
      })
    });
  }

  render() {
    return (
        <div>
          <section className="section">
            <div className="block">
              <h1 className="title">Events</h1>
            </div>
            <div className="block">
              <div className="content">
                Hier soll eine Anleitung hin.
              </div>
              <Link className="button is-primary is-medium" to={"/event/create"}>Event Erstellen</Link>
            </div>
          </section>
          <section className="section">
            <h1 className="subtitle">Veröffentlicht</h1>
            <div className="columns is-multiline">
                {
                  this.state.events.filter(event => (event.state === "PUBLISHED")).map((event, i) => {
                    return (
                        <div key={i} className="column is-one-third">
                          <div className="card">
                            <div className="card-image">
                              <figure className="image is-1by1">
                                <img src={event.images.overview.src} alt="Event"/>
                              </figure>
                            </div>
                            <div className="card-content">

                              <p className="title is-4">{event.name}</p>

                              <p style={{"display": "-webkit-box", "WebkitLineClamp": "3", "WebkitBoxOrient": "vertical", "height": "4.5em", "overflow": "hidden", "textOverflow": "ellipsis"}}>{event.description}</p>
                              <br/>

                              <p><b>Datum:</b></p>
                              <p>{moment.unix(event.date.from).format("DD.MM.YYYY HH:mm")} - {moment.unix(event.date.to).format("DD.MM.YYYY HH:mm")}</p>
                              <br/>

                              <p><b>Adresse:</b></p>
                              {event.address.type === "PRESENCE" ?
                                <p>{event.address.name}</p>
                              :
                                <p>{event.address.platform}</p>
                              }
                              <br/>

                              <p><b>Anmeldung:</b></p>
                              <p>{event.registration.required ? "Ja" : "Nein"}</p>

                            </div>
                            <footer className="card-footer">
                              <Link className="card-footer-item" to={`/event/${event._id}`}>Verwalten</Link>
                            </footer>
                          </div>
                        </div>
                    )})
                }
              </div>
          </section>

          <section className="section">
            <h1 className="subtitle">Nicht veröffentlicht</h1>
          </section>
          <section className="section">
            <h1 className="subtitle">Werden genehmigt</h1>
          </section>
          <section className="section">
            <h1 className="subtitle">In Bearbeitung</h1>
          </section>
        </div>
    );
  }
}

export default withRouter(EventsPage);
