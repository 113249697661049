import React from 'react';
import {
  withRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';

//Scss
import './App.scss';

//Components
import Navbar from "./components/Navbar";
import LoginPage from "./components/LoginPage";
import DashboardPage from "./components/DashboardPage";
import EventCreatePage from "./components/EventCreatePage";
import GetAccessPage from "./components/GetAccessPage";
import SignUpPage from "./components/SignUpPage";
import EventRegistrationPage from "./components/EventRegistrationPage";
import PasswordResetPage from "./components/PasswordResetPage";
import PasswordResetRequestPage from "./components/PasswordResetRequestPage";
import EventNotExists from "./components/EventNotExists";
import EventPage from "./components/EventPage";
import EventsPage from "./components/EventsPage";
import UserAdministrationPage from "./components/UserAdministrationPage";
import NewsPage from "./components/NewsPage";


const requestSender = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  timeout: 1000,
  withCredentials: true
});

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        permission: "DEFAULT"
      }
    }

    this.getUserData = this.getUserData.bind(this);
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData() {
    if(Cookies.get("authToken")) {

      requestSender.get("admin/user/data").then((res) => {
        this.setState({
          user: res.data.user
        });
      }).catch((err) => {

        Cookies.remove("authToken");
        //this.props.history.push("/login");
      });
    }
  }

  render () {
    return (
        <div>
          <Navbar user={this.state.user}/>

          <div className="container">

            <Switch>
              <Route path="/" exact>
                <Redirect to={{pathname: "/dashboard"}}/>
              </Route>

              <Route path="/passwordResetRequest" exact>
                <PasswordResetRequestPage requestSender={requestSender}/>
              </Route>

              <Route path="/passwordReset/:passwordResetToken" exact>
                <PasswordResetPage requestSender={requestSender}/>
              </Route>

              <Route path="/login" exact>
                <LoginPage requestSender={requestSender} getUserData={this.getUserData}/>
              </Route>

              <Route path="/signup/:invitationToken" exact>
                <SignUpPage requestSender={requestSender}/>
              </Route>

              <Route path="/getAccess" exact>
                <GetAccessPage requestSender={requestSender}/>
              </Route>


              <Route path="/event/:eventId/register" exact>
                <EventRegistrationPage requestSender={requestSender}/>
              </Route>

              <Route path="/event/not" exact>
                <EventNotExists/>
              </Route>


              <PrivateRoute path="/event/create" exact>
                <EventCreatePage requestSender={requestSender} user={this.state.user}/>
              </PrivateRoute>

              <PrivateRoute path="/event/create/:eventId" exact>
                <EventCreatePage requestSender={requestSender} user={this.state.user}/>
              </PrivateRoute>

              <PrivateRoute path="/event/create/:eventId/:step">
                <EventCreatePage requestSender={requestSender} user={this.state.user}/>
              </PrivateRoute>


              <PrivateRoute path="/dashboard" exact>
                <DashboardPage requestSender={requestSender}/>
              </PrivateRoute>

              <PrivateRoute path="/events" exact>
                <EventsPage requestSender={requestSender}/>
              </PrivateRoute>

              <PrivateRoute path="/users">
                <UserAdministrationPage requestSender={requestSender}/>
              </PrivateRoute>

              <PrivateRoute path="/news">
                <NewsPage/>
              </PrivateRoute>

              <PrivateRoute path="/event/:eventId" exact>
                <EventPage requestSender={requestSender}/>
              </PrivateRoute>
            </Switch>
          </div>
        </div>
    );
  };
}

function PrivateRoute({children, ...rest }) {

  return (
      <Route
          {...rest}
          render={({ location }) =>
              Cookies.get("authToken") ? (
                  children
              ) : (
                  <Redirect to={{pathname: "/login"}}/>
              )
          }
      />
  );
}

export default withRouter(App);
