import React, {Component} from 'react';
import {
  withRouter
} from 'react-router-dom';

class NewsPage extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <section className="section">
        <h1 className="title">News</h1>
        <p>Coming Soon</p>
      </section>
    );
  }
}

export default withRouter(NewsPage);