import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter } from 'react-router-dom';

import EventCreateSteps from "./EventCreate/EventCreateSteps";
import EventCreateType from "./EventCreate/EventCreateType";
import LoadingModule from "./LoadingModule";
import EventCreateInformation from "./EventCreate/EventCreateInformation";
import EventCreatePublish from "./EventCreate/EventCreatePublish";
import EventCreateReview from "./EventCreate/EventCreateReview";

class EventCreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      event: {
        _id: this.props.match.params.eventId
      },
      templates: []
    };

  }

  componentDidMount() {
    if(this.state.event._id){
      this.props.requestSender.get(`/admin/events/${this.state.event._id}`).then((res) => {
        this.setState({
          event: res.data.event
        });

      }).catch((err) => {
        this.props.history.push("/event/not");
      });

    } else {

      this.props.requestSender.post("/admin/events/create").then((res) => {

        this.props.requestSender.get(`/admin/events/${res.data._id}`).then((res) => {
          this.setState({
            event: res.data.event
          });
          this.props.history.replace(`/event/create/${res.data.event._id}`);
        }).catch((err) => {
          //TODO: Error Handling
        });

      }).catch((err) => {
        //TODO: Error Handling
      });
    }

    this.props.requestSender.get("/admin/events/templates").then((res) => {
      this.setState({
        loading: false,
        templates: res.data.templates
      })
    }).catch((err) => {
      //TODO: Error Handling
    });
  }


  handleTemplateChange = (event, template) => {
    if(this.state.event.template._id !== template._id){

      this.setState({
        event: {
          _id: this.state.event._id,
          state: this.state.event.state,
          name: template.name ? template.name : "",
          description: template.description ? template.description : "",
          date: {
            from: 0,
            to: 0
          },
          images: {
            overview: template.images.overview ? {
              _id: template.images.overview._id,
              src: template.images.overview.src
            } : null
          },
          address: template.address ? (template.address.type === "PRESENCE" ? {
            type:  template.address.type,
            name:  template.address.name ? template.address.name : "",
            street: template.address.street ? template.address.street : "",
            houseNumber: template.address.houseNumber ? template.address.houseNumber : "",
            city: template.address.city ? template.address.city : "",
            postcode: template.address.postcode ? template.address.postcode : "",
            latitude: template.address.latitude ? template.address.latitude : 0,
            longitude: template.address.longitude ? template.address.longitude : 0,
            platform: "",
            link: ""
          } : {
            type:  template.address.type,
            name:  "",
            street: "",
            houseNumber: "",
            city: "",
            postcode: "",
            latitude: 0,
            longitude: 0,
            platform: template.address.platform ? template.address.platform : "",
            link: template.address.link ? template.address.link : ""
          }) : {
            type: "PRESENCE",
            name: "",
            street: "",
            houseNumber: "",
            city: "",
            postcode: "",
            latitude: 0,
            longitude: 0,
            platform: "",
            link: ""
          }, /*TODO: Can be removed when every template has an address*/
          registration: template.registration ? {
            required: template.registration.required,
            until: 0,
            hasLimit: template.registration.hasLimit,
            limit: template.registration.limit
          } : {
            required: false,
            until: 0,
            hasLimit: false,
            limit: 0
          }, /*TODO: Can be removed when every template has an registration*/
          template: template
        }
      });

    } else {

      this.setState({
        event: {
          _id: this.state.event._id,
          state: this.state.event.state,
          name: "",
          description: "",
          date: {
            from: 0,
            to: 0
          },
          images: {
            overview: null
          },
          address: {
            type: "PRESENCE",
            name: "",
            street: "",
            houseNumber: "",
            city: "",
            postcode: "",
            latitude: 0,
            longitude: 0,
            platform: "",
            link: ""
          },
          registration: {
            required: false,
            until: 0,
            hasLimit: false,
            limit: ""
          },
          template: {}
        }
      });
    }

  };

  handleEventChange = async (e, event) => {
    this.setState({
      event: {
        ...this.state.event,
        ...event,
      }
    });

    return await this.props.requestSender.post(`/admin/events/${this.props.match.params.eventId}/save`, {
      name: event.name,
      description: event.description,
      date: event.date,
      images: {
        overview: event.images.overview._id ? event.images.overview._id : {
          _id: null,
          src: ""
        }
      },
      address: event.address.type === "PRESENCE" ? {
        type: event.address.type,
        name: event.address.name,
        city: event.address.city,
        postcode: event.address.postcode,
        street: event.address.street,
        houseNumber: event.address.houseNumber,
        latitude: event.address.latitude,
        longitude: event.address.longitude
      } : {
        type: event.address.type,
        platform: event.address.platform,
        link: event.address.link
      },
      registration: {
        required: event.registration.required,
        until: event.registration.required ? event.registration.until : 0,
        hasLimit: event.registration.hasLimit,
        limit: event.registration.limit,
      },
      template_id: this.state.event.template._id
    });
  }

  handleEventStateChange = (state) => {
    this.setState({
      event: {
        ...this.state.event,
        state: state
      }
    });
  }


  render(){
    return (
        <div>
          {this.state.loading === true ?
            <LoadingModule/>
          :
            <div>
              <div className="section">
                <EventCreateSteps /*step={this.state.step}*//>
              </div>

              <div className="columns is-centered">
                <div className="column is-two-thirds">
                  <div>
                    <Switch>
                      <Route path="/event/create/:eventId/type" exact>
                        {this.state.event.state === "IN_PROGRESS" ?
                          <EventCreateType templates={this.state.templates} template={this.state.event.template} handleTemplateChange={this.handleTemplateChange}/>
                        :
                          <Redirect to={{pathname: `/event/create/${this.state.event._id}`}}/>
                        }
                      </Route>

                      <Route  path="/event/create/:eventId/information" exact>
                        {this.state.event.state === "IN_PROGRESS" ?
                          <EventCreateInformation requestSender={this.props.requestSender}
                                                  event={this.state.event}
                                                  template={this.state.event.template}
                                                  handleEventChange={this.handleEventChange}
                                                  handleEventStateChange={this.handleEventStateChange}/>
                        :
                          <Redirect to={{pathname: `/event/create/${this.state.event._id}`}}/>
                        }
                      </Route>

                      <Route  path="/event/create/:eventId/review" exact>
                        {this.state.event.state === "PROGRESSED" ?
                          <EventCreateReview requestSender={this.props.requestSender}
                                             event={this.state.event}
                                             user={this.props.user}
                                             handleEventStateChange={this.handleEventStateChange}/>
                        :
                          <Redirect to={{pathname: `/event/create/${this.state.event._id}`}}/>
                        }
                      </Route>

                      <Route  path="/event/create/:eventId/publish" exact>
                        {this.state.event.state === "REVIEWED" ?
                          <EventCreatePublish requestSender={this.props.requestSender}
                                              event={this.state.event}
                                              handleEventStateChange={this.handleEventStateChange}/>
                        :
                          <Redirect to={{pathname: `/event/create/${this.state.event._id}`}}/>
                        }
                      </Route>

                      <Route path="/event/create/:eventId" exact>
                        {this.state.event.state === "IN_PROGRESS" ?
                          <Redirect to={{pathname: `${this.props.location.pathname}/type`}}/>
                        : this.state.event.state === "PROGRESSED" ?
                          <Redirect to={{pathname: `${this.props.location.pathname}/review`}}/>
                        : this.state.event.state === "REVIEWED" ?
                          <Redirect to={{pathname: `${this.props.location.pathname}/publish`}}/>
                        :
                          ""
                        }
                      </Route>

                      <Route path="/event/create/:eventId/:step" exact>
                        <Redirect to={{pathname: `/event/create/${this.props.match.params.eventId}/type`}}/>
                      </Route>
                    </Switch>

                    {/*<div className="section">
                      <div className="field is-grouped">
                        <div className="control">
                          <button className="button" onClick={this.handleNextStep} value="TYPE">Zurück</button>
                        </div>
                        <div className="control">
                          <Link className="button is-primary" to="/event/create/information">Weiter</Link>
                          <button className="button is-primary" onClick={this.handleNextStep} value="INFORMATION">Weiter</button>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
    );
  }
}

export default withRouter(EventCreatePage);
