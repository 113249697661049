import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

class PasswordResetRequestPage extends Component {

  constructor() {
    super();

    this.state = {
      loading: false,
      email: ""
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event){
    this.setState({email: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({ loading: true });

    this.props.requestSender.post('/admin/user/passwordResetRequest', {
      email: this.state.email
    }).then((res) => {
      this.setState({
        loading: false,
        failed: false
      });

    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true
      });
    });
  }

  render() {
    return (
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="title">Passwort Zurücksetzen</h1>

              <div className="block">
                <p>Gebe deine Email an, um dein Passwort zurücksetzen zu können.</p>
              </div>
              <div className="block">
                <form onSubmit={this.handleSubmit}>
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <input className="input is-medium" type="text" placeholder="h.potter@stuv-loerrach.de" onChange={this.handleEmailChange}/>
                    </div>
                    <div className="control">
                      <button type="submit" className={`button is-medium is-primary ${this.state.loading ? "is-loading" : ""}`}>
                        Abschicken
                      </button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(PasswordResetRequestPage);