import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

class LoadingModule extends Component {
  render() {
    return (
        <div className="loader-wrapper is-active">
          <div className="loader is-loading"></div>
        </div>
    );
  }
}

export default withRouter(LoadingModule);