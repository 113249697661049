import React, {Component} from 'react';
import {
  withRouter,
  Link
} from "react-router-dom";

class EventCreateType extends Component {

  render() {
    return (
        <div className={`section`}>
          <div className="block">
            <h1 className="title">Event Typ</h1>
          </div>
          <div className="block">
            <div className="buttons">
              {
                this.props.templates.map((template, i) => {
                  return <button key={i} className={`button is-light ${this.props.template.type === template.type ? "is-primary" : ""}`} onClick={(event) => this.props.handleTemplateChange(event, template)}>{template.name}</button>
                })
              }
            </div>
          </div>

          <div className="block">
            <div className="field is-grouped">
              <div className="control">
                <Link className="button is-primary" type="button" to={`/event/create/${this.props.match.params.eventId}/information`}>Weiter</Link>
              </div>

            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(EventCreateType);
