import React from 'react';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

//Ressources
import logo from '../dhbw.svg';

class Navbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expandBurgerMenu: false
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.handleToggleBurgerMenu = this.handleToggleBurgerMenu.bind(this);
  }

  handleLogout(event){
    Cookies.remove("authToken");

    this.props.history.push("/login");
  }

  handleToggleBurgerMenu(event){
    this.setState({
      expandBurgerMenu: !this.state.expandBurgerMenu
    })
  }

  render() {

    return (
        <header>
          <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <a className="navbar-item" href="http://admin.stuv-loerrach.de">
                <img src={logo} alt="" height="2.5rem"/>
                <h1 className="navbar-item is-size-4">
                  Stuv Admin
                </h1>
              </a>

              <div className={`navbar-burger ${Cookies.get("authToken") ? "" : "is-hidden"} ${this.state.expandBurgerMenu ? "is-active" : ""}`}
                   style={{alignSelf: "center"}}
                   onClick={this.handleToggleBurgerMenu}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </div>
            </div>

            <div className={`navbar-menu ${Cookies.get("authToken") ? "" : "is-hidden"} ${this.state.expandBurgerMenu ? "is-active" : ""}`}>
              <div className="navbar-start">
                <Link className="navbar-item" to="/dashboard">Dashboard</Link>
                <Link className="navbar-item" to="/events">Events</Link>
                <Link className="navbar-item" to="/news">News</Link>

              </div>
              <div className="navbar-end">
                {this.props.user.permission === "ADMIN" ?
                  <Link className="navbar-item" to="/users">Verwalten</Link>
                :
                  ""
                }
                <div className="navbar-item">
                  <button className="button is-primary" onClick={this.handleLogout}><strong>Ausloggen</strong></button>
                </div>
              </div>
            </div>
          </nav>
        </header>
    );
  }
}

export default withRouter(Navbar);