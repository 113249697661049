import React from 'react';
class DashboardPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      events: []
    }
  }

  componentDidMount() {
    this.props.requestSender.get('/admin/event', {
      withCredentials: true
    }).then((res) => {
      this.setState({
        loading: false,
        events: res.data.events
      });

      //for(const event in this.state.events){

      //}
      console.log(res);
    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true});
    });
  }


  render() {

    return (
        <div className="section">
          <div className="block">
            <h1 className="title">Willkommen bei Stuv-Admin</h1>

            {/*<nav className="level">
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Nächstes Event</p>
                  <p className="title">3 März</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Geplante Events</p>
                  <p className="title">{this.state.events.length}</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Anmeldungen</p>
                  <p className="title">345</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="heading">Auslastung</p>
                  <p className="title">50%</p>
                </div>
              </div>
            </nav>*/}
          </div>


          {/*<table className="table is-fullwidth is-hoverable has-text-centered">
            <thead>
              <tr>
                <th>Event</th>
                <th>Datum</th>
                <th>Ort</th>
                <th>Plätze</th>
                <th>Anmeldungen</th>
                <th>Überprüft</th>
                <th>Veröffentlicht</th>
                <th>Adminstration</th>
              </tr>
            </thead>
            <tbody>
              {this.state.events.map((event, index) => {
                return (
                    <TableItemEvent event={event}/>
                );
              })}
            </tbody>
          </table>*/}
        </div>
    );
  }
}

export default DashboardPage;