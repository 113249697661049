import React, { Component } from 'react';
import {
  Link,
  withRouter } from 'react-router-dom';

class EventNotExists extends Component {

  render() {
    return (
        <div className="hero is-medium">
          <div className="hero-body">
            <div className="block">
              <p className="title">
                Ups! Das Event existiert nicht
              </p>
            </div>
            <div className="block">
              <div className="field">
                <div className="control">
                  <Link className="button is-primary" to="/event/create">Neues Erstellen</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(EventNotExists);