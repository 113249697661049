import React from 'react';
import { withRouter } from 'react-router-dom';

class GetAccessPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failed: false,

      email: ""
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({loading: true});

    /*this.props.requestSender.post('/admin/user/login', {
      email: this.state.email,
      password: this.state.password
    }).then((res) => {
      this.setState({
        loading: false,
        failed: false
      });
      this.props.history.push("/dashboard");

      console.log(res);
    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true});
    });*/
  }

  render() {

    return (
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="title">Zugang anfordern</h1>
              <div className="block">
                <p>Als Stuv-Mitglied kannst du mit deiner Stuv-Mail Zugang zu unserem Stuv-Admin Portal anfordern. Ein Admin wird dich im laufe der nächsten Tage kontaktieren und freischalten.</p>
              </div>
              <form onSubmit={this.handleSubmit}>

                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input className="input is-medium" type="text" placeholder="harry.potter@stuv-loerrach.de" disabled={"true"}/>
                  </div>
                  <div className="control">
                    <button className="button is-medium is-primary" disabled={"true"}>
                      Zugang anfordern
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(GetAccessPage);