import React from 'react';
import { withRouter } from 'react-router-dom';

class SignUpPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failed: false,

      firstName: "",
      lastName: "",
      password: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {

  }

  handleFirstNameChange(event) {
    this.setState({firstName: event.target.value});
  }

  handleLastNameChange(event) {
    this.setState({lastName: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value})
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({loading: true});

    this.props.requestSender.post('/admin/user/signup', {
      invitationToken: this.props.match.params.invitationToken,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password
    }).then((res) => {
      this.setState({
        loading: false,
        failed: false
      });

      this.props.history.push("/login");
    }).catch((err) => {

      this.setState({
        loading: false,
        failed: true
      });
    });
  }

  render() {

    return (
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="title">Sign Up</h1>

              <div className="block">
                <form onSubmit={this.handleSubmit}>

                  {/*<div className="field">
                    <label className="label">Stuv-Mail</label>
                    <div className="control has-icons-left has-icons-right">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="email" placeholder="h.potter@stuv-loerrach.de" value={this.state.email} onChange={this.handleEmailChange}/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                    </div>
                  </div>*/}

                  <div className="field">
                    <label className="label">Vorname</label>
                    <div className="control">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="text" autoComplete="given-name" placeholder="Harry" value={this.state.firstName} onChange={this.handleFirstNameChange}/>
                    </div>
                    {/*<p className={`help is-danger ${this.state.failed ? "" : "is-hidden"}`}>Logindaten falsch</p>*/}
                  </div>

                  <div className="field">
                    <label className="label">Nachname</label>
                    <div className="control">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="text" autoComplete="family-name" placeholder="Potter" value={this.state.lastName} onChange={this.handleLastNameChange}/>
                    </div>
                    {/*<p className={`help is-danger ${this.state.failed ? "" : "is-hidden"}`}>Logindaten falsch</p>*/}
                  </div>

                  <div className="field">
                    <label className="label">Passwort</label>
                    <div className="control has-icons-left">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="password" placeholder="Fortuna_Major" value={this.state.password} onChange={this.handlePasswordChange}/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </div>
                    {/*<p className={`help is-danger ${this.state.failed ? "" : "is-hidden"}`}>Logindaten falsch</p>*/}
                  </div>

                  <div className="field">
                    <div className="control">
                      <button type="submit" className={`button has-content-centered is-fullwidth is-primary ${this.state.loading ? "is-loading" : ""}`}>Singup</button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(SignUpPage);