import React, {Component} from 'react';
import {
  withRouter
} from 'react-router-dom';
import LoadingModule from "./LoadingModule";
import moment from "moment";

class EventPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      event: null,
      participants: []
    }
  }

  componentDidMount() {
    if(this.props.match.params.eventId){
      this.props.requestSender.get(`/admin/events/${this.props.match.params.eventId}`).then((res) => {
        this.setState({
          loading: false,
          event: res.data.event
        });

        if(res.data.event.registration.required){
          this.props.requestSender.get(`/admin/events/${this.props.match.params.eventId}/participants`).then((res) => {
            this.setState({
              participants: res.data.participants
            })
          });
        }
      });
    }

  }

  render() {
    return (
        <div className="section">
          {this.state.loading ?
            <LoadingModule/>
          :
            <div>
              <section className= "section">
                <div className="block">
                  <h1 className="title">Event</h1>
                </div>

                <h1 className="subtitle">Übersicht</h1>

                <div className="columns is-centered">
                  <div className="column is-two-thirds">

                    <div className="block">
                      <div className="field">
                        <label className="label">Name</label>
                        <div className="content">
                          <p>{this.state.event.name}</p>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Beschreibung</label>
                        <div className="content">
                          <p>{this.state.event.description}</p>
                        </div>
                      </div>
                    </div>

                    <div className="block">
                      <div className="field">
                        <label className="label">Von</label>
                        <div className="content">
                          <p>{moment.unix(this.state.event.date.from).format("DD.MM.YYYY HH:mm")}</p>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Bis</label>
                        <div className="content">
                          <p>{moment.unix(this.state.event.date.to).format("DD.MM.YYYY HH:mm")}</p>
                        </div>
                      </div>
                    </div>

                    <div className="block">
                      <label className="label">Adresse</label>
                      { this.state.event.address.type === "PRESENCE" ?
                        <div>
                          <div className="field">
                            <div className="content">
                              <p>{this.state.event.address.name}</p>
                            </div>
                          </div>
                          <div className="field">
                            <div className="content">
                              <p>{this.state.event.address.street} {this.state.event.address.houseNumber}</p>
                            </div>
                          </div>
                          <div className="field">
                            <div className="content">
                              <p>{this.state.event.address.postcode} {this.state.event.address.city}</p>
                            </div>
                          </div>
                        </div>
                      :
                        <div className="field">
                          <div className="content">
                            <p>{this.state.event.address.platform} {this.state.event.address.link}</p>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="block">
                      <div className="field">
                        <label className="label">Anmeldung</label>
                        <div className="content">
                          <p>Benötigt: {this.state.event.registration.required ? "Ja" : "Nein"}</p>
                        </div>
                      </div>
                      {this.state.event.registration.required ?
                          <div>
                            <div className="field">
                              <div className="content">
                                <p>Bis: {moment.unix(this.state.event.registration.until).format("DD.MM.YYYY")}</p>
                              </div>
                            </div>
                            <div className="field">
                              <div className="content">
                                <p>Teilnehmer: {this.state.event.registration.hasLimit ? `Maximal ${this.state.event.registration.limit}` : "Unbegrenzt"}</p>
                              </div>
                            </div>
                          </div>
                          :
                          ""
                      }
                    </div>
                  </div>
                  <div className="column is-one-third">
                    <figure className="image is-1by1">
                      <img src={this.state.event.images.overview.src}></img>
                    </figure>
                  </div>
                </div>
              </section>

              {this.state.event.registration.required ?
                  <section className="section">
                    <h1 className="subtitle">Teilnehmer</h1>

                    <table className="table is-fullwidth">
                      <thead>
                      <tr className="has-text-left">
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>E-Mail</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.participants.length !== 0 ?
                          this.state.participants.map((participant, i) => {
                            return (
                                <tr className="has-text-left">
                                  <td>{participant.firstName}</td>
                                  <td>{participant.lastName}</td>
                                  <td>{participant.email}</td>
                                </tr>);
                          })
                          :
                          <tr>
                            <td>Keine Teilnehmer vorhanden</td>
                          </tr>
                      }
                      </tbody>
                    </table>
                    <button className="button is-success">
                  <span className="icon">
                    <i className="fas fa-download"></i>
                  </span>
                      <span>Exportieren</span>
                    </button>
                  </section>
                  :
                  ""
              }

              <section className="section">
                <h1 className="subtitle">Verwalten</h1>
                <div className="block">
                  <div className="field">
                    <div className="control">
                      <button className="button is-primary">Absagen</button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          }

        </div>
    );
  }
}

export default withRouter(EventPage);