import React, {Component} from 'react';
import {
  Link,
  withRouter } from 'react-router-dom';
import moment from 'moment';
import SelectImageModal from "./ImageSelectModal";

class EventCreateInformation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      addresses: [],
      addressDropDownCollapse: false,
      imageSelectModalIsActive: false,
      unsavedChanges: false,

      event: {
        _id: this.props.event._id,
        name: this.props.event.name,
        description: this.props.event.description,
        date: {
          from: this.props.event.date.from === 0 ? moment().unix() : this.props.event.date.from,
          to: this.props.event.date.to === 0 ? moment().unix() : this.props.event.date.to,
        },
        images: {
          overview: this.props.event.images.overview ? {
            _id: this.props.event.images.overview._id,
            src: this.props.event.images.overview.src
          } : {
            _id: null,
            src: ""
          }
        },
        address: {
          type:  this.props.event.address.type ? this.props.event.address.type : "PRESENCE",
          name:  this.props.event.address.name ? this.props.event.address.name : "",
          street: this.props.event.address.street ? this.props.event.address.street : "",
          houseNumber: this.props.event.address.houseNumber ? this.props.event.address.houseNumber : "",
          city: this.props.event.address.city ? this.props.event.address.city : "",
          postcode: this.props.event.address.postcode ? this.props.event.address.postcode : "",
          latitude: this.props.event.address.latitude ? this.props.event.address.latitude: 0,
          longitude: this.props.event.address.longitude ? this.props.event.address.longitude: 0,
          platform: this.props.event.address.platform ? this.props.event.address.platform : "",
          link: this.props.event.address.link ? this.props.event.address.link : ""
        },
        registration: {
          required: this.props.event.registration.required,
          until: this.props.event.registration.until === 0 ? moment().unix() : this.props.event.registration.until,
          hasLimit: this.props.event.registration.hasLimit,
          limit: this.props.event.registration.limit
        }
      }
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

    this.handleImageSelectModalClose = this.handleImageSelectModalClose.bind(this);
    this.handleImageSelectModalOpen = this.handleImageSelectModalOpen.bind(this);

    this.handleDateFromChange = this.handleDateFromChange.bind(this);
    this.handleDateToChange = this.handleDateToChange.bind(this);

    this.handleAddressDropDownCollapse = this.handleAddressDropDownCollapse.bind(this);

    this.handleAddressTypeChange = this.handleAddressTypeChange.bind(this);
    this.handleAddressNameChange = this.handleAddressNameChange.bind(this);
    this.handleAddressStreetChange = this.handleAddressStreetChange.bind(this);
    this.handleAddressHouseNumberChange = this.handleAddressHouseNumberChange.bind(this);
    this.handleAddressCityChange = this.handleAddressCityChange.bind(this);
    this.handleAddressPostcodeChange = this.handleAddressPostcodeChange.bind(this);
    this.handleAddressLatitudeChange = this.handleAddressLatitudeChange.bind(this);
    this.handleAddressLongitudeChange = this.handleAddressLongitudeChange.bind(this);
    this.handleAddressPlatformChange = this.handleAddressPlatformChange.bind(this);
    this.handleAddressLinkChange = this.handleAddressLinkChange.bind(this);

    this.handleRegistrationRequiredChange = this.handleRegistrationRequiredChange.bind(this);
    this.handleRegistrationUntilChange = this.handleRegistrationUntilChange.bind(this);
    this.handleRegistrationHasLimitChange = this.handleRegistrationHasLimitChange.bind(this);
    this.handleRegistrationLimitChange = this.handleRegistrationLimitChange.bind(this);

    this.handleReviewClick = this.handleReviewClick.bind(this); //TODO: Change funktion name
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount(){
    this.props.requestSender.get("/admin/events/addresses").then((res) => {
      this.setState({
        addresses: res.data.addresses
      });

    }).catch((err) => {
      //TODO: Error Handling
    });
  }

  handleSaveClick(event) {
    this.props.handleEventChange(event, this.state.event).then((res) => {
      this.setState({
        unsavedChanges: false
      })
    }).catch((err) => {
      if(err.response.status === 400) {
        alert(err.response.data.error);
      }
    });
  }

  handleReviewClick(event){
    this.props.requestSender.post(`/admin/events/${this.state.event._id}/progress`).then( (res) => {
      this.props.handleEventStateChange("PROGRESSED");
      this.props.history.push(`/event/create/${this.state.event._id}/review`);
    }).catch( (err) => {
      if(err.response.status === 400) {
        alert(err.response.data.error);
      }

    });
  }


  handleNameChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        name: event.target.value
      }
    });
  }

  handleDescriptionChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        description: event.target.value
      }
    });
  }

  handleImageChange = (event, image) => {
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        images: {
          overview: {
            _id: image._id,
            src: image.src
          }
        }
      }
    });
  }

  handleImageSelectModalOpen(event){
    this.setState({
      imageSelectModalIsActive: true
    })
  }

  handleImageSelectModalClose(event){
    this.setState({
      imageSelectModalIsActive: false
    })
  }

  handleDateFromChange(event){
    const a = document.getElementById("dateFrom");
    const b = document.getElementById("timeFrom");
    const date = moment(a.value + b.value, "YYYY-MM-DD HH:mm");

    if(date.isValid()){
      this.setState({
        unsavedChanges: true,
        event: {
          ...this.state.event,
          date: {
            ...this.state.event.date,
            from: date.unix()
          }
        }
      });
    }

  }

  handleDateToChange(event){
    const a = document.getElementById("dateTo");
    const b = document.getElementById("timeTo");
    const date = moment(a.value + b.value, "YYYY-MM-DD HH:mm");

    if(date.isValid()){
      this.setState({
        unsavedChanges: true,
        event: {
          ...this.state.event,
          date: {
            ...this.state.event.date,
            to: date.unix()
          }
        }
      });
    }
  }

  handleAddressDropDownClick(event, name){
    event.preventDefault();

    const address = this.state.addresses.find((address) => {
      return address.name === name;
    });

    this.setState({
      addressDropDownCollapse: !this.state.addressDropDownCollapse,
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: address ? {
          type: address.type ? address.type : "PRESENCE",
          name: address.name  ? address.name : "",
          street: address.street  ? address.street : "",
          houseNumber: address.houseNumber ? address.houseNumber : "",
          city: address.city ? address.city : "",
          postcode: address.postcode ? address.postcode : "",
          latitude: address.latitude ? address.latitude : 0,
          longitude: address.longitude ? address.longitude : 0,
          platform: address.platform ? address.platform : "",
          link: address.link ? address.link : ""
        } : {
          type: "PRESENCE",
          name: "",
          street: "",
          houseNumber: "",
          city: "",
          postcode: "",
          latitude: 0,
          longitude: 0,
          platform: "",
          link: ""
        }
      }
    });
  }

  handleAddressDropDownCollapse(event) {
    this.setState({
      addressDropDownCollapse: !this.state.addressDropDownCollapse
    });
  }


  handleAddressTypeChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          type: this.state.event.address.type === "PRESENCE" ? "ONLINE" : "PRESENCE"
        }
      }
    });
  }

  handleAddressNameChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          name: event.target.value
        }
      }
    });
  }

  handleAddressStreetChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          street: event.target.value
        }
      }
    });
  }

  handleAddressHouseNumberChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          houseNumber: event.target.value
        }
      }
    });
  }

  handleAddressCityChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          city: event.target.value
        }
      }
    });
  }

  handleAddressPostcodeChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          postcode: event.target.value
        }
      }
    });
  }

  handleAddressLatitudeChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          latitude: event.target.value
        }
      }
    });
  }

  handleAddressLongitudeChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          longitude: event.target.value
        }
      }
    });
  }

  handleAddressPlatformChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          platform: event.target.value
        }
      }
    });
  }

  handleAddressLinkChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        address: {
          ...this.state.event.address,
          link: event.target.value
        }
      }
    });
  }


  handleRegistrationRequiredChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        registration: {
          ...this.state.event.registration,
          required: !this.state.event.registration.required
        }
      }
    });
  }

  handleRegistrationUntilChange(event){
    const date =  moment(event.target.value, "YYYY-MM-DD");

    if(date.isValid()){
      this.setState({
        unsavedChanges: true,
        event: {
          ...this.state.event,
          registration: {
            ...this.state.event.registration,
            until: date.unix()
          }
        }
      });
    }
  }

  handleRegistrationHasLimitChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        registration: {
          ...this.state.event.registration,
          hasLimit: !this.state.event.registration.hasLimit
        }
      }
    });
  }

  handleRegistrationLimitChange(event){
    this.setState({
      unsavedChanges: true,
      event: {
        ...this.state.event,
        registration: {
          ...this.state.event.registration,
          limit: event.target.value
        }
      }
    });
  }


  render() {
    return (
        <div className="section">
          <SelectImageModal requestSender={this.props.requestSender} isActive={this.state.imageSelectModalIsActive} handleModalClose={this.handleImageSelectModalClose} handleImageChange={this.handleImageChange} image={this.state.event.images.overview}/>

          <form onSubmit={this.handleSubmit}>
            <div className="block">
              <h1 className="title">Event Informationen</h1>
            </div>

            {/*Title and Description Block*/}
            <div className="block">
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input className="input" type="text" placeholder="Event Name"
                         value={this.state.event.name} onChange={this.handleNameChange} required/>
                </div>
              </div>

              <div className="field">
                <label className="label">Beschreibung</label>
                <div className="control">
                <textarea className="textarea" placeholder="Event Beschreibung"
                          value={this.state.event.description} onChange={this.handleDescriptionChange} required/>
                </div>
              </div>
            </div>

            {/*Image Block*/}
            <div className="columns">
              <div className="column is-half">
                <div className="block">
                  <div className="field">
                    <label className="label">Bild</label>
                    <figure className="image is-1by1">
                      {this.state.event.images.overview._id ?
                        <img className="is-rounded-4px" src={this.state.event.images.overview.src} alt="Event Bild"/>
                      :
                        <div className="img-overlay has-background-light is-rounded-4px" style={{"opacity": "1.0"}}>
                          <i className="fas fa-images has-text-grey" style={{"fontSize": "3em"}}/>
                        </div>
                      }
                    </figure>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className="button is-success" type="button" onClick={this.handleImageSelectModalOpen}>
                        <span className="icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span>Ändern</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Date and Time Block*/}
            <div className="block">
              <div className="field">
                <label className="label">Von</label>

                <div className="field-body">
                  <div className="field is-narrow">
                    <div className="control">
                      <input id="dateFrom" className="input" type="date"
                             min={moment().format("YYYY-MM-DD")}
                             value={moment.unix(this.state.event.date.from).format("YYYY-MM-DD")}
                             onChange={this.handleDateFromChange} required/>
                    </div>
                  </div>
                  <div className="field is-narrow">
                    <div className="control">
                      <input id="timeFrom" className="input" type="time"
                             value={moment.unix(this.state.event.date.from).format("HH:mm")}
                             onChange={this.handleDateFromChange} required/>

                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Bis</label>
                <div className="field-body">
                  <div className="field is-narrow">
                    <div className="control">
                      {/*TODO: has to be greater than date from*/}
                      <input id="dateTo" className="input" type="date"
                             min={moment.unix(this.state.event.date.from).format("YYYY-MM-DD")}
                             value={moment.unix(this.state.event.date.to).format("YYYY-MM-DD")}
                             onChange={this.handleDateToChange} required/>
                    </div>
                  </div>
                  <div className="field is-narrow">
                    <div className="control">
                      <input id="timeTo" className="input" type="time"
                             value={moment.unix(this.state.event.date.to).format("HH:mm")}
                             onChange={this.handleDateToChange} required/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Address Block*/}
            <div className="block">
              <div className="field">
                <label className="label">Adresse</label>

                <div className="control">
                  <div className={`dropdown ${this.state.addressDropDownCollapse ? "is-active" : ""}`}>

                    <div className="dropdown-trigger">
                      <button className="button" type="button" aria-haspopup="true" aria-controls="address-menu"
                              onClick={this.handleAddressDropDownCollapse}>
                        <span>Wähle Adresse</span>
                        <span className="icon is-small">
                            <i className="fas fa-angle-down" aria-hidden="true"/>
                          </span>
                      </button>
                    </div>

                    <div className="dropdown-menu" id="address-menu" role="menu">
                      <div className="dropdown-content">
                        {
                          this.state.addresses.map((address, i) => {
                            return (
                                <a key={i} className="dropdown-item" href={address.name} onClick={(event) => this.handleAddressDropDownClick(event, address.name)}>
                                  {address.name}
                                </a>
                            );
                          })
                        }
                        <hr className="dropdown-divider"/>
                        <a className="dropdown-item" href="eigene" onClick={(event) => this.handleAddressDropDownClick(event)}>
                          Eigene
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <input className="is-checkradio" type="checkbox" id="addressIsOnline" name="addressIsOnline"
                         onChange={this.handleAddressTypeChange}
                         checked={`${this.state.event.address.type === "ONLINE" ? "checked" : ""}`}/>
                  <label htmlFor="addressIsOnline">Online</label>
                </div>
              </div>

              {/*PRESENCE or ONLINE Address*/}
              {this.state.event.address.type === "PRESENCE" ?
                  <div>
                    <div className="field">
                      <div className="control">
                        <input className="input" type="text" placeholder="Name"
                               value={this.state.event.address.name} onChange={this.handleAddressNameChange} required/>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" placeholder="Straße"
                                   value={this.state.event.address.street} onChange={this.handleAddressStreetChange} required/>
                          </div>
                        </div>
                        <div className="field is-narrow">
                          <div className="control">
                            <input className="input" type="text" placeholder="Hausnummer"
                                   value={this.state.event.address.houseNumber} onChange={this.handleAddressHouseNumberChange} required/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" placeholder="Stadt"
                                   value={this.state.event.address.city} onChange={this.handleAddressCityChange} required/>
                          </div>
                        </div>
                        <div className="field is-narrow">
                          <div className="control">
                            <input className="input" type="text" placeholder="Postleitzahl"
                                   value={this.state.event.address.postcode} onChange={this.handleAddressPostcodeChange} required/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" placeholder="Breitengrad"
                                   value={this.state.event.address.latitude} onChange={this.handleAddressLatitudeChange} required/>
                          </div>
                        </div>
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" placeholder="Längengrad"
                                   value={this.state.event.address.longitude} onChange={this.handleAddressLongitudeChange} required/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="field">
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" placeholder="Plattform"
                                   value={this.state.event.address.platform} onChange={this.handleAddressPlatformChange} required/>
                          </div>
                        </div>
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" placeholder="Link"
                                   value={this.state.event.address.link} onChange={this.handleAddressLinkChange} required/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>

            {/*Registration Block*/}
            <div className="block">
              <div className="field">
                <label className="label">Anmeldung</label>
                <div className="control">
                  <input className="is-checkradio" type="checkbox" id="hasRegistration" name="hasRegistration"
                         value={this.state.event.registration.required}
                         onChange={this.handleRegistrationRequiredChange}
                         checked={`${this.state.event.registration.required ? "checked" : ""}`}/>
                  <label htmlFor="hasRegistration">Anmeldung benötigt</label>
                </div>
              </div>

              {this.state.event.registration.required ?
                  <div>
                    <div className="field">
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <input className="input" type="date"
                                   min={moment().format("YYYY-MM-DD")}
                                   value={moment.unix(this.state.event.registration.until).format("YYYY-MM-DD")}
                                   max={moment.unix(this.state.event.date.from).format("YYYY-MM-DD")}
                                   onChange={this.handleRegistrationUntilChange} required/>
                          </div>
                          <p className="help">Letzter Anmeldetag</p>
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <input className="is-checkradio" type="checkbox" id="hasRegistrationLimit" name="hasRegistrationLimit"
                               value={this.state.event.registration.hasLimit}
                               onChange={this.handleRegistrationHasLimitChange}
                               checked={`${this.state.event.registration.hasLimit ? "checked" : ""}`}/>
                        <label htmlFor="hasRegistrationLimit">Teilnehmer begrenzen</label>
                      </div>
                    </div>

                    <div className="field">
                      <div className="field-body">
                        <div className="control is-narrow">
                          <input className="input" type="number" placeholder="Limit"
                                 value={this.state.event.registration.limit}
                                 onChange={this.handleRegistrationLimitChange}
                                 disabled={this.state.event.registration.hasLimit ? "" : "disabled"} required/>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""
              }

            </div>

            <div className="block">
              <div className="field is-grouped">
                <div className="control">
                  <Link className="button" type="button" to={`/event/create/${this.props.match.params.eventId}/type`}>Zurück</Link>
                </div>
                <div className="control">
                  <button className="button is-success" type="button" onClick={this.handleSaveClick}>Speichern</button>
                </div>
                <div className="control">
                  <button className="button is-primary" type="button" onClick={this.handleReviewClick}>Zur Prüfung freigeben</button>
                </div>
              </div>
              {this.state.unsavedChanges ?
                <p className="help is-danger" >Änderungen wurden nicht gespeichert!</p>
              :
                ""
              }

            </div>
          </form>


        </div>
    );
  }
}

export default withRouter(EventCreateInformation);
