import React from 'react';
import {withRouter} from 'react-router';

class EventCreateSteps extends React.Component {

  constructor(props) {
    super(props);

    /*
    steps:
      TYPE
      INFORMATION
      REVIEW
      PUBLISH
     */
    this.state = {
      step: this.props.match.params.step ? this.props.match.params.step.toUpperCase() : ""
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.step) {
      if (this.state.step !== this.props.match.params.step.toUpperCase()) {
        this.setState({
          step: this.props.match.params.step.toUpperCase()
        });
      }
    }
  }

  render() {
    return (
        <ul className="steps has-content-centered">
          <li className={`steps-segment ${this.state.step === "TYPE" ? "is-active" : ""} ${this.state.step === "TYPE" ? "has-gaps" : ""}`}>
            <a href="" className="has-text-dark">
                  <span className="steps-marker">
                    <div className="icon">
                      <span className="fas fa-beer"/>
                    </div>
                  </span>
              <div className="steps-content">
                <p className="heading">Event Typ</p>
              </div>
            </a>
          </li>
          <li className={`steps-segment ${this.state.step === "INFORMATION" ? "is-active" : ""} ${this.state.step === "TYPE" || this.state.step === "INFORMATION" ? "has-gaps" : ""} `}>
            <a href="" className="has-text-dark">
                  <span className="steps-marker">
                    <div className="icon">
                      <span className="fas fa-calendar-day"/>
                    </div>
                  </span>
              <div className="steps-content">
                <p className="heading">Event Informationen</p>
              </div>
            </a>
          </li>
          <li className={`steps-segment ${this.state.step === "REVIEW" ? "is-active" : ""} ${this.state.step === "TYPE" || this.state.step === "INFORMATION" || this.state.step === "REVIEW" ? "has-gaps" : ""} `}>
            <a href="" className="has-text-dark">
                  <span className="steps-marker">
                    <div className="icon">
                      <span className="fas fa-check"/>
                    </div>
                  </span>
              <div className="steps-content">
                <p className="heading">Prüfung</p>
              </div>
            </a>
          </li>
          <li className={`steps-segment ${this.state.step === "PUBLISH" ? "is-active" : ""}`}>
            <a href="" className="has-text-dark">
                  <span className="steps-marker">
                    <div className="icon">
                      <span className="fas fa-users"/>
                    </div>
                  </span>
              <div className="steps-content">
                <p className="heading">Veröffentlichen</p>
              </div>
            </a>
          </li>
        </ul>
    );
  }

}

export default withRouter(EventCreateSteps);