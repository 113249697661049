import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

class PasswordResetPage extends Component {

  constructor(){
    super();

    this.state = {
      failed: false,
      loading: false,

      password: "",
      passwordRepeat: ""
    }

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordRepeatChange = this.handlePasswordRepeatChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handlePasswordRepeatChange(event) {
    this.setState({passwordRepeat: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    if(this.state.password !== this.state.passwordRepeat) {
      this.setState({
        failed: true
      });

      return;
    }

    this.props.requestSender.post('/admin/user/passwordReset', {
      passwordResetToken: this.props.match.params.passwordResetToken,
      password: this.state.password
    }).then((res) => {
      this.setState({
        loading: false,
        failed: false
      });
      this.props.history.push("/dashboard");

      console.log(res);
    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true
      });
    });

  }

  render() {
    return (
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="title">Passwort Ändern</h1>

              <div className="block">
                <p>Wähle ein neues Passwort</p>
              </div>

              <div className="block">
                <form onSubmit={this.handleSubmit}>
                  <div className="field">
                    <label className="label">Neues Passwort</label>
                    <div className="control has-icons-left">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="password" placeholder="Caput_Draconis" onChange={this.handlePasswordChange}/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="password" placeholder="Wiederholen" onChange={this.handlePasswordRepeatChange}/>
                    </div>
                    <p className={`help is-danger ${this.state.failed ? "" : "is-hidden"}`}>Passwörter stimmen nicht überein!</p>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-primary is-fullwidth">Passwort Ändern</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(PasswordResetPage);