import React, {Component} from 'react';
import {
  withRouter
} from "react-router-dom";

class ImageSelectModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: []
    }

  }

  componentDidMount() {
    this.props.requestSender("/admin/events/images").then((res) => {
      this.setState({
        images: res.data.images
      });
    });
  }


  render() {
    return (
        <div className={`modal ${this.props.isActive ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <section className="modal-card-head">
              <p className="modal-card-title">Bild Ändern</p>
            </section>
            <section className="modal-card-body">
              <div className="block">
                <figure className="image is-1by1">
                  {this.props.image._id ?
                        <img className="is-rounded-4px" src={this.props.image.src} align="Event Bild"/>
                      :
                      <div className="img-overlay has-background-light is-rounded-4px" style={{"opacity": "1.0"}}>
                        <i className="fas fa-images has-text-grey" style={{"fontSize": "3em"}}/>
                      </div>
                  }

                </figure>
              </div>
              <div className="block">
                <div style={{"display": "flex", "flexDirection": "row", "flexWrap": "wrap", "gap": "1em"}}>
                  {
                    this.state.images.map((image, i) => {
                      return  (
                          <div>
                            <div>
                              <figure className="image is-96x96" key={i} onClick={(event) => this.props.handleImageChange(event, image)}>
                                <img className="is-rounded-4px" src={image.src}/>

                                <div className={`img-overlay is-rounded-4px has-background-light ${image._id === this.props.image._id ? "is-active" : ""}`}>
                                  <i className="fas fa-check has-text-grey" style={{"fontSize": "3em"}}/>
                                </div>
                              </figure>

                            </div>

                          </div>
                      );
                    })
                  }

                  <figure className="image is-96x96">
                    <div className="img-overlay is-rounded-4px has-background-light" style={{"opacity": "1.0"}}>
                      <i className="fas fa-plus has-text-grey" style={{"fontSize": "3em"}}/>
                    </div>
                  </figure>
                </div>
              </div>
            </section>
            <section className="modal-card-foot">
              <button className="button is-success" type="button" onClick={this.props.handleModalClose}>Auswählen</button>
            </section>
          </div>
          <button className="modal-close is-large" type="button" aria-label="close" onClick={this.props.handleModalClose}></button>
        </div>
    );
  }
}

export default withRouter(ImageSelectModal);