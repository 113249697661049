import React from 'react';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";

class LoginPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failed: false,

      email: "",
      password: ""
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({loading: true});

    this.props.requestSender.post('/admin/user/login', {
      email: this.state.email,
      password: this.state.password
    }).then((res) => {

      this.setState({
        loading: false,
        failed: false
      });

      this.props.getUserData();

      this.props.history.push("/dashboard");

    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true
      });
    });
  }

  render() {

    return (
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="title">Login</h1>

              <div className="block">
                <form onSubmit={this.handleSubmit}>

                  <div className="field">
                    <label className="label">Stuv-Mail</label>
                    <div className="control has-icons-left has-icons-right">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="email" placeholder="h.potter@stuv-loerrach.de" value={this.state.email} onChange={this.handleEmailChange}/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Passwort</label>
                    <div className="control has-icons-left">
                      <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="password" placeholder="Fortuna Major" value={this.state.password} onChange={this.handlePasswordChange}/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </div>
                    <p className={`help is-danger ${this.state.failed ? "" : "is-hidden"}`}>Logindaten falsch</p>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button type="submit" className={`button has-content-centered is-fullwidth is-primary ${this.state.loading ? "is-loading" : ""}`}>Login</button>
                    </div>
                  </div>

                  <div className="field">
                    <Link className="link" to="/passwordResetRequest">Passwort vergessen?</Link>
                  </div>

                  <div className="field">
                    <p>Du brauchst Zugang? Dann klick <Link className="link" to="/getAccess">hier</Link>!</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(LoginPage);