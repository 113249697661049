import React from 'react';
import { withRouter } from 'react-router';

class EventRegistrationPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failed: false,

      eventId: this.props.match.params.eventId,
      event: null,
      firstName: "",
      lastName: "",
      email: ""
    };

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.requestSender.get(`/event/${this.state.eventId}`).then((res) => {
      this.setState({
        event: res.data.event
      });
    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true
      });
    });
  }

  handleFirstNameChange(event) {
    this.setState({firstName: event.target.value});
  }

  handleLastNameChange(event) {
    this.setState({lastName: event.target.value});
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({loading: true});

    this.props.requestSender.post('/event/register', {
      event_id: this.state.eventId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email
    }).then((res) => {
      this.setState({
        loading: false,
        failed: false
      });

    }).catch((err) => {
      this.setState({
        loading: false,
        failed: true
      });
    });
  }

  render() {

    return (
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              {this.state.event !== null ?
                  <div>
                    <h1 className="title">Anmelden</h1>
                    <div className="block">
                      <h2 className="subtitle">{this.state.event.title}</h2>
                      <p></p>
                    </div>

                    <div className="block">
                      <form onSubmit={this.handleSubmit}>

                        <div className="field">
                          <div className="label">Vorname</div>
                          <input className="input" type="text" placeholder="Harry" autoComplete="given-name" required="true" onChange={this.handleFirstNameChange}/>
                        </div>

                        <div className="field">
                          <div className="label">Nachname</div>
                          <input className="input" type="text" placeholder="Potter" autoComplete="family-name" required="true" onChange={this.handleLastNameChange}/>
                        </div>

                        <div className="field">
                          <label className="label">DHBW-Mail</label>
                          <div className="control has-icons-left has-icons-right">
                            <input className={`input ${this.state.failed ? "is-danger" : ""}`} type="email" placeholder="h.potter@dhbw-loerrach.de" onChange={this.handleEmailChange}/>
                            <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <button type="submit" className={`button has-content-centered is-fullwidth is-primary ${this.state.loading ? "is-loading" : ""}`}>Login</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
              :
                  <div>
                  {this.state.failed === true ?
                    <div>
                      <h1 className="title">Anmelden</h1>
                      <div className="block">
                        <p>
                          Das Event existiert nicht!
                        </p>
                      </div>
                    </div>
                      :
                    ""
                  }
                  </div>
              }

            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(EventRegistrationPage);