import React, {Component} from 'react';
import {
  withRouter
} from 'react-router-dom';

class UserAdministrationPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users: []
    }
  }

  componentDidMount() {

    this.props.requestSender.get("/admin/user/").then((res) => {
      this.setState({
        users: res.data.users
      })
    });
  }

  render() {
    return (
        <div className="section">
          <h1 className="title">Nutzer</h1>

          <table className="table is-fullwidth">
            <thead>
            <tr className="has-text-left">
              <th>Vorname</th>
              <th>Nachname</th>
              <th>E-Mail</th>
              <th>Rolle</th>
              <th>Verwalten</th>
            </tr>
            </thead>
            <tbody>
              {this.state.users.map((user, i) => {
                  return (
                      user.status === "REGISTERED" ?
                          <tr className="has-text-left">
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>
                              <span className={`tag is-medium is-light ${user.permission === "ADMIN" ? "is-primary": "is-light"}`}>{user.permission}</span>
                            </td>
                            <td>
                              <button className="button is-primary" disabled={true}>Löschen</button>
                            </td>
                          </tr>
                      :
                          ""
                  );
                })
              }
            </tbody>
          </table>
        </div>
    );
  }
}

export default withRouter(UserAdministrationPage);
